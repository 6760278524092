<template>
  <div v-if="consultation">
    <div class="bg-danger">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <div class="text-center">
            <h4 class="text-white mb-0">Consultation [Doctor]</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container custom-container">
      <div
        class="
          position-relative
          d-flex
          justify-content-center
          align-items-center
          p-3
        "
      >
        <div class="card custom-card overflow-hidden">
          <!-- top -->
          <div class="card-body p-0">
            <div class="row justify-content-end">
              <div v-if="doctor" class="col col-md-6 col-sm-12">
                <div
                  class="
                    p-3
                    d-flex
                    justify-content-between
                    align-items-center
                    container-top-profile
                  "
                >
                  <div class="img-container rounded-circle mb-3 mr-3">
                    <img
                      src="/img/person_placeholder_2.jpg"
                      class="rounded-circle"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                  <div style="width: calc(100% - 60px)">
                    <h6 class="m-0">{{ currentCustomer.user.username }}</h6>
                    <p>Customer / Patient</p>
                  </div>
                </div>
              </div>
              <div class="col col-md-6 col-sm-12">
                <div class="d-flex justify-content-end p-3">
                  <button
                    type="button"
                    class="btn btn-success btn-sm mr-2"
                    @click="handleCall"
                  >
                    <i class="ri-phone-line align-middle mr-2"></i>Call
                  </button>
                  <button type="button" class="btn btn-info btn-sm">
                    <i class="ri-vidicon-line align-middle mr-2"></i>Video Call
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- card chat -->
          <!-- navigation -->
          <div class="card-body p-0">
            <ul class="nav nav-tabs-custom">
              <li
                :class="
                  activeBtn == 'chat'
                    ? 'nav-item w-50 text-center active'
                    : 'nav-item w-50 text-center '
                "
                @click="activeBtn = 'chat'"
              >
                Chat
              </li>
              <li
                :class="
                  activeBtn == 'prescriptions'
                    ? 'nav-item w-50 text-center active'
                    : 'nav-item w-50 text-center '
                "
                @click="activeBtn = 'prescriptions'"
              >
                Prescription
              </li>
            </ul>
          </div>
          <!-- content chat-->
          <div class="card-body p-0" v-if="activeBtn == 'chat'">
            <div id="chat-container" class="chat-container p-3">
              <div v-for="message in messages" :key="message.id">
                <div
                  v-if="message.user.id == currentCustomer.user.id"
                  class="d-flex bubble-left"
                >
                  <div class="img-thumbnails rounded-circle">
                    <img
                      src="/img/person_placeholder_2.jpg"
                      class="rounded-circle"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                  <div style="max-width: calc(100% - 35px)" class="pl-2">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-2
                      "
                    >
                      <!-- <h6 class="m-0 small-title">Dr Abram Ekstrom Bothman</h6> -->
                      <p class="m-0 text-muted small">
                        {{ getDatetimeFormat(message.sent_datetime) }}
                      </p>
                    </div>
                    <div class="bubble bg-light py-2 px-3 rounded">
                      <p>{{ message.text }}</p>
                      <img
                        v-if="message.file != null"
                        :src="getImageLink(message.file)"
                        width="100"
                        data-toggle="modal"
                        data-target="#view-image-modal"
                        @click="selectedImage = message.file"
                      />
                    </div>
                  </div>
                </div>
                <div v-else class="d-flex bubble-right">
                  <div style="max-width: calc(100% - 35px)" class="pr-2">
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mb-2
                      "
                    >
                      <!-- <h6 class="m-0 small-title">You</h6> -->
                      <p class="m-0 text-muted small">
                        {{ getDatetimeFormat(message.sent_datetime) }}
                      </p>
                    </div>
                    <div class="bubble bg-light py-2 px-3 rounded">
                      <p>{{ message.text }}</p>
                      <img
                        v-if="message.file != null"
                        :src="getImageLink(message.file)"
                        width="100"
                        data-toggle="modal"
                        data-target="#view-image-modal"
                        @click="selectedImage = message.file"
                      />
                    </div>
                  </div>
                  <div class="img-thumbnails rounded-circle">
                    <img
                      :src="getImageLink(doctor.profile_img)"
                      class="rounded-circle"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0 border-top" v-if="activeBtn == 'chat'">
            <div class="d-flex justify-content-between align-items-center p-3">
              <input
                type="text"
                v-model="textMessage"
                placeholder="message..."
                class="form-control w-100 mr-2"
              />
              <input
                id="file-input"
                type="file"
                style="display: none"
                ref="fileInput"
                @change="submitFileMessage"
              />
              <button
                type="button"
                class="btn btn-danger mr-2 bg-attachment"
                @click="triggerFileInput()"
              >
                <i class="ri-attachment-line"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger bg-submit"
                @click="submitTextMessage()"
              >
                <i class="ri-send-plane-line"></i>
              </button>
            </div>
          </div>
          <!-- content prescriptions -->
          <div
            class="card-body"
            v-if="activeBtn == 'prescriptions' && selectedPrescription"
          >
            <div class="card">
              <div class="card-body bg-light">
                <div class="row">
                  <div class="col-md-7 col-sm-12">
                    <h6 class="small-title">Full Name</h6>
                    <p class="small fw-lighter">
                      63 Jalan Utas 15/7 Seksyen 15 40000 Shah Alam Malaysia
                    </p>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <div class="mb-2">
                      <h6 class="small-title">Issued On</h6>
                      <p class="small fw-lighter">20 December 2021</p>
                    </div>
                    <div>
                      <h6 class="small-title">Expiry Date</h6>
                      <p class="small fw-lighter">30 December 2021</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body bg-white border-top">
                <div
                  v-if="
                    selectedPrescription.prescription_items &&
                    selectedPrescription.prescription_items.length > 0
                  "
                >
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col" style="width: 10%">gm/ml</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in selectedPrescription.prescription_items"
                        :key="item.id"
                      >
                        <td>{{ item.product.title }}</td>
                        <td>{{ item.quantity_limit }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-body bg-light border-top">
                <div class="row">
                  <div class="col-md-7 col-sm-12">
                    <h6 class="small-title m-0">Issued By</h6>
                    <p class="m-0 small">
                      {{ selectedPrescription.doctor.name }}
                    </p>
                    <p class="m-0 small fw-ligther">Clinic ABC</p>
                  </div>
                  <div class="col-md-5 col-sm-12">
                    <h6 class="small-title m-0">Prescription ID :</h6>
                    <span class="small fw-ligther">{{
                      selectedPrescription.prescription_name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewImageModal"
      aria-hidden="true"
      id="view-image-modal"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <img v-if="selectedImage" :src="getImageLink(selectedImage)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../utils/API";
import { mapGetters, mapActions } from "vuex";
import Pusher from "pusher-js";
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  name: "Consultation",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      activeBtn: "chat",
      doctor: null,
      chat: null,
      textMessage: null,
      messages: [],
      selectedImage: null,
      prescriptions: [],
      selectedPrescription: null,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation"]),
  },
  methods: {
    ...mapActions(["setNewConsultation"]),
    async initAll() {
      if (!this.consultation) {
        this.$router.push("/e-prescription/choose-doctor");
        // await this.setNewConsultation({
        //   doctorID: '61fe187aa6263a7671f5fd33', //hardcoded first, nnti amik dari choose doctor page.
        //   // doctorID: '61feb96f3555cb264f04c091', //hardcoded first, nnti amik dari choose doctor page.
        // })
      }

      this.doctor = this.consultation.doctor;

      //get messages chat
      const res = await API.get(`/chats/${this.consultation.chat.id}`);
      this.chat = res.data;
      this.messages = this.chat.messages;

      this.prescriptions = this.consultation.prescriptions;
      const res02 = await API.get("/prescriptions/" + this.prescriptions[0].id);
      this.selectedPrescription = res02.data;

      setInterval(async () => {
        //just for mocking data
        await this.refetchPrescription();
        await this.refreshChat();
      }, 5000);
    },
    async refreshChat() {
      const res = await API.get(`/chats/${this.consultation.chat.id}`);
      this.chat = res.data;
      this.messages = this.chat.messages;
    },
    initPusher() {
      Pusher.logToConsole =
        process.env.VUE_APP_PUSHER_DEBUG === "true" ? true : false;
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: "ap1",
      });

      const channel = pusher.subscribe("chat-channel");
      let context = { title: "Pusher" };
      let handler = (_data) => {
        this.messages.push(_data.currentMessage);
      };
      channel.bind("my-event", handler, context);
    },
    async refetchPrescription() {
      //just for mocking data
      const res = await API.get("/prescriptions/" + this.prescriptions[0].id);
      this.selectedPrescription = res.data;
    },
    handleCall() {
      var num = "tel:+60133257420";
      window.location.href = num;
    },
    getImageLink(_img) {
      return API.getAPIUrl() + _img.url;
    },
    getDatetimeFormat(_datetime) {
      return moment(_datetime).format("dddd h:mma");
    },
    chatScrollBottom() {
      let el = document.getElementById("chat-container");
      el.scrollTo({ top: el.scrollHeight, behavior: "smooth" });
    },
    async submitTextMessage() {
      //save chat message
      let messages2send = [];
      messages2send = messages2send.concat(this.messages);
      messages2send.push({
        user: this.consultation.doctor.user,
        text: this.textMessage,
        // file: null,
        sent_datetime: moment(),
      });
      this.textMessage = null;

      const res = await API.put(`/chats/${this.consultation.chat.id}`, {
        messages: messages2send,
      });

      //call push chat message to trigger
      await API.post("/custom/push-chat-messages", {
        chatID: this.consultation.chat.id,
        currentMessage: res.data.messages[res.data.messages.length - 1],
      });

      this.chatScrollBottom();
    },
    triggerFileInput() {
      document.getElementById("file-input").click();
    },
    async submitFileMessage(_event) {
      // console.log('e : ', _event)
      // console.log('e ref: ', this.$refs.fileInput)
      let formData = new FormData();
      formData.append("files", this.$refs.fileInput.files[0]);
      let resFileUpload = await API.post("/upload", formData);

      //save chat message
      let messages2send = [];
      messages2send = messages2send.concat(this.messages);
      messages2send.push({
        user: this.consultation.doctor.user,
        // text: this.textMessage,
        file: resFileUpload.data[0].id,
        sent_datetime: moment(),
      });

      const res = await API.put(`/chats/${this.consultation.chat.id}`, {
        messages: messages2send,
      });

      //call push chat message to trigger
      await API.post("/custom/push-chat-messages", {
        chatID: this.consultation.chat.id,
        currentMessage: res.data.messages[res.data.messages.length - 1],
      });

      this.chatScrollBottom();
    },
  },
  async mounted() {
    await this.initAll();
    await this.initPusher();
    this.chatScrollBottom();
  },
};
</script>

<style scoped>
.custom-card {
  width: 550px;
}
.custom-container {
  height: calc(100vh - 142px);
  overflow: scroll;
}
.img-container {
  height: 60px;
  width: 60px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.img-container img {
  height: auto;
  width: 100%;
}

.img-thumbnails {
  height: 35px;
  width: 35px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.img-thumbnails img {
  height: auto;
  width: 100%;
}

.bubble-right {
  margin-bottom: 25px;
  justify-content: flex-end;
}

.small-title {
  font-size: 0.8rem;
}

.bubble-right .bubble {
  background-color: #912018 !important;
  color: white !important;
}

.bubble-left {
  margin-bottom: 25px;
  justify-content: flex-start;
}

.nav-tabs-custom li {
  color: #667085 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer !important;
  padding-bottom: 5px;
}

.nav-tabs-custom .active {
  color: red !important;
  border-bottom: 1px solid red !important;
}

.nav-tabs-custom .active {
  color: red !important;
}
.nav-tabs-custom .active:hover {
  color: red !important;
  border-bottom: 1px solid red !important;
}

.nav-tabs-custom li:hover {
  color: #4e576b !important;
  border-bottom: 1px solid #4e576b !important;
}

.chat-container {
  height: 400px;
  overflow: scroll;
}

.bg-submit {
  border-color: #f97066 !important;
  background-color: #f97066 !important;
  color: white !important;
}
.bg-attachment {
  background-color: #fef3f2 !important;
  color: #b42318 !important;
}

.fw-lighter {
  font-weight: lighter;
}

::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 720px) {
  .container-top-profile {
    display: block !important;
  }
}
</style>